import React from "react";

const Home = (props) => {
  console.log('RENDERS THE HOME COMPONENT')

  return (
    <div style={{ display: "block" }}>
      Welcome to zoom Dreamschools
    </div>
  );
};

export default Home;
