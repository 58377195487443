import { Switch, BrowserRouter, Route } from "react-router-dom";
import ZoomSDK from './ZoomSDK';
import Home from './Home';


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route
            path="/"
            exact
            component={Home}
          />
          <Route
            path="/zoom-online/:meeting_number/:meeting_password/:user"
            component={ZoomSDK}
          />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
