import React, { useEffect, useState } from "react";
// import PropTypes from 'prop-types'
import axios from "axios";
import { ZoomMtg } from "@zoomus/websdk";
ZoomMtg.setZoomJSLib("https://dmogdx0jrul3u.cloudfront.net/1.9.0/lib", "/av");
ZoomMtg.preLoadWasm();
ZoomMtg.prepareJssdk();

// const zm = document.getElementById("zmmtg-root");
// if (zm) {
//   zm.style.display = "none";
// }
const ZoomSDK = (props) => {
  const [launchMeeting, setLaunchMeeting] = useState(false);

  console.log('RENDERS THE ZOOM COMPONENT');
  console.log('props.match.params.user', props.match.params.user);

  const meetingConfig = {
    leaveUrl: `${process.env.REACT_APP_URL}/user/my-enrollments`, // /zoom-online/${props.match.params.meeting_number}/${props.match.params.meeting_password}`,
    // leaveUrl: `http://localhost:3002`, // /user/zoom-online/${props.match.params.meeting_number}/${props.match.params.meeting_password}`,
    apiKey: `${process.env.REACT_APP_ZOOM_WEB_SDK_API}`,
    // apiKey: 'NkH0DHz6RO2mJKLnYqemRw',
    meetingNumber: parseInt(props.match.params.meeting_number),
    userName: props.match.params.user ? `${props.match.params.user}` : "User",
    passWord: props.match.params.meeting_password,
    role: 0,
  };
  async function generateSignature() {
    setLaunchMeeting(true);
    // if (zm) {
    //   zm.style.display = "block";
    //   zm.style.zIndex = 20000;
    // }

    const ep = `${process.env.REACT_APP_API}/partner/online_virtual/zoom-sdk`;
    const resp = await axios.post(ep, { meetingData: meetingConfig });
    if (!resp.data.success) {
      return;
    }
    startMeeting(resp.data.signature);
  }

  function startMeeting(signature) {
    console.log("starting meeting on zoom");

    ZoomMtg.init({
      leaveUrl: meetingConfig.leaveUrl,
      isSupportAV: true,
      success: function (s) {
        console.log("success from zoom init", s);
        console.log("trying to join");
        ZoomMtg.join({
          signature: signature,
          apiKey: meetingConfig.apiKey,
          meetingNumber: meetingConfig.meetingNumber,
          userName: meetingConfig.userName,
          passWord: meetingConfig.passWord,
          success: function (success) {
            console.log("joining success:", success);
          },
          error: (joinerr) => {
            console.log("join error:", joinerr);
          },
        });
      },
      error: (initerr) => {
        console.log("zoom init error:", initerr);
      },
    });
  }

  useEffect(() => {
    console.log("init zoom sdk. geeting signature");
    generateSignature();
    // }
  }, []);
  return (
    <div style={{ display: "block" }}>
      {/* {
                !launchMeeting &&
                (<><button onClick={generateSignature} className="btn btn-primary">
                    Join Meeting
                </button></>
                )
            } */}
    </div>
  );
};

ZoomSDK.propTypes = {};

export default ZoomSDK;
